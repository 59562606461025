@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: pan-x pan-y;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.titleBar {
 /* Make sure the `div` stays there, even when scrolling. */
 position: fixed;
 /**
  * Gradient, because why not. Endless opportunities.
  * The gradient ends in `#36c`, which happens to be the app's
  * `<meta name="theme-color" content="#36c">`.
  */
 /* Use the environment variable for the left anchoring with a fallback. */
 left: env(titlebar-area-x, 0);
 /* Use the environment variable for the top anchoring with a fallback. */
 top: env(titlebar-area-y, 0);
 /* Use the environment variable for setting the width with a fallback. */
 width: env(titlebar-area-width, 100%);
 /* Use the environment variable for setting the height with a fallback. */
 height: env(titlebar-area-height, 33px);
  /* Layout the child items from left to right */
  flex-direction: row; 
  /* Use flexbox to control the layout of this div */
  display: flex;

 z-index: 99;
 background-color: #F8BBD0 !important;
 align-items: center;
}

.main-bg {
  background-color: #FCE4EC;
}